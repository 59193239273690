import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Gallery = class Gallery extends Vue {
    constructor() {
        super(...arguments);
        this.showItem = false;
        this.activeItemIndex = 0;
    }
    toggleImage(index) {
        this.activeItemIndex = index;
        this.showItem = !this.showItem;
    }
    updateImage(index) {
        this.activeItemIndex = index;
    }
    nextItem() {
        this.activeItemIndex =
            this.activeItemIndex < this.items.length - 1 ? this.activeItemIndex + 1 : 0;
    }
    prevItem() {
        this.activeItemIndex = this.activeItemIndex > 0 ? this.activeItemIndex - 1 : this.items.length - 1;
    }
};
__decorate([
    Prop()
], Gallery.prototype, "items", void 0);
Gallery = __decorate([
    Component
], Gallery);
export default Gallery;
