import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
// components
// @ts-ignore 
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);
let SocialShare = class SocialShare extends Vue {
    constructor() {
        super(...arguments);
        this.networks = [
            { network: 'email', name: 'Email', icon: ['fal', 'envelope'] },
            { network: 'facebook', name: 'Facebook', icon: ['fab', 'facebook'] },
            { network: 'linkedin', name: 'LinkedIn', icon: ['fab', 'linkedin'] },
            { network: 'telegram', name: 'Telegram', icon: ['fab', 'telegram-plane'] },
            { network: 'twitter', name: 'Twitter', icon: ['fab', 'twitter'] },
            { network: 'whatsapp', name: 'Whatsapp', icon: ['fab', 'whatsapp'] },
        ];
    }
    mounted() {
        if (!this.sharing) {
            this.sharing = {
                url: process.env.VUE_APP_URL,
                title: this.$i18n.t('social.share_title'),
                description: this.$i18n.t('social.share_description'),
                quote: this.$i18n.t('social.share_quote'),
                hashtags: this.$i18n.t('social.share_hashtags'),
                twitterUser: this.$i18n.t('social.share_twitter_user')
            };
        }
    }
};
__decorate([
    Prop()
], SocialShare.prototype, "sharing", void 0);
SocialShare = __decorate([
    Component
], SocialShare);
export default SocialShare;
